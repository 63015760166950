<template>
  <div class="d-block">
    <div class="py-1" style="background-color: #fbfbfb; top: -1px">
      <div class="radio-toggles" v-bind:class="isDate ? 'dateStyle' : null">
        <template v-for="period in report.periodFilters">
          <input
            type="radio"
            name="radio-options"
            class="radio-switch__input"
            v-bind:key="'input-' + period.id"
            v-bind:id="`option-${period.id}`"
            v-bind:checked="report.period == period.id"
            v-on:change="report.period = period.id"
          />
          <label
            class="radio-switch__label"
            v-bind:for="`option-${period.id}`"
            v-bind:key="'label-' + period.id"
            v-html="period.name"
          ></label>
        </template>
        <WeDatePicker
          v-if="report.period == 'custom_date'"
          class="position-absolute custom-date"
          style="z-index: 1020; width: 85%; left: -3px"
          id="custom-date"
          label="Başlangıç Tarih/Saat"
          v-bind:format="'DD.MM.YYYY HH:mm'"
          v-bind:formatted="'ll'"
          v-bind:output-format="'YYYY-MM-DD HH:mm'"
          v-bind:locale="'tr'"
          v-bind:no-label="true"
          v-bind:no-button-now="true"
          v-bind:no-clear-button="false"
          v-bind:range="true"
          v-bind:custom-shortcuts="dateShortCuts"
          v-model="report.periodDate"
        />
        <div class="slide-item"></div>
        <div class="slide-item-mobile"></div>
      </div>
    </div>
    <div class="row align-items-stretch">
      <div class="col-12 mb-4" v-if="cards">
        <Cards />
      </div>
      <div class="col-12 col-lg-6 mb-4" v-if="choropleth">
        <Choropleth />
      </div>
      <div class="col-12 col-lg-6 mb-4" v-if="cartAverage">
        <CartAverage />
      </div>
      <div class="col-12 col-lg-6 mb-4" v-if="product">
        <Product />
      </div>
      <div class="col-12 col-lg-6 mb-4" v-if="categoryProduct">
        <CategoryProduct />
      </div>
    </div>
  </div>
</template>
<script>
const Cards = () => import("./Cards/Index");
const Choropleth = () => import("./Choropleth/Index");
const CartAverage = () => import("./CartAverage/Index");
const Product = () => import("./Product/Index");
const CategoryProduct = () => import("./CategoryProduct/Index");

import { mapState } from "vuex";

export default {
  name: "WeWidget",
  props: {
    cards: {
      default: "",
    },
    choropleth: {
      default: "",
    },
    cartAverage: {
      default: "",
    },
    product: {
      default: "",
    },
    categoryProduct: {
      default: "",
    },
  },
  components: {
    Cards,
    Choropleth,
    CartAverage,
    Product,
    CategoryProduct,
  },
  data() {
    return {
      isDate: false,
      dateShortCuts: [
        { key: "thisWeek", label: "Bu Hafta", value: "isoWeek" },
        { key: "lastWeek", label: "Geçen Hafta", value: "-isoWeek" },
        { key: "last7Days", label: "Son 7 Gün", value: 7 },
        { key: "last30Days", label: "Son 30 Gün", value: 30 },
        { key: "thisMonth", label: "Bu Ay", value: "month" },
        { key: "lastMonth", label: "Geçen Ay", value: "-month" },
        { key: "thisYear", label: "Bu Yıl", value: "year" },
        { key: "lastYear", label: "Geçen Yıl", value: "-year" },
      ],
    };
  },
  computed: {
    ...mapState({
      report: (state) => state.widget.reports,
    }),
  },
  watch: {
    "report.period": function (newVal) {
      if (newVal == "custom_date") {
        this.report.periodDate = {
          start: new Date().toISOString(),
          end: null,
        };
        this.isDate = true;
      } else {
        this.report.periodDate = null;
        this.isDate = false;
      }
    },
    "report.periodDate": function (newVal) {
      if (!newVal && this.report.period == "custom_date") {
        this.report.period = "today";
      }
    },
  },
};
</script>
<style>
.radio-toggles {
  align-items: center;
  background: #eee;
  border: 1px solid lightgrey;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  max-width: 850px;
  /* overflow: hidden; */
  padding: 4px;
  position: relative;
}

#custom-date-wrapper input {
  border-radius: 9999px;
}

.radio-switch__label {
  cursor: pointer;
  padding: 5px;
  text-align: center;
  width: 14.28%;
  z-index: 2;
  margin: 0;
}

.slide-item {
  background: white;
  border-radius: 9999px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  height: calc(100% - 8px);
  left: calc(14.28% + 4px);
  position: absolute;
  width: calc(14.28% - 8px);
  transition: left 0.4s;
  z-index: 0;
}

.radio-switch__input[type="radio"] {
  left: -9999px;
  position: absolute;
  z-index: -1;
}
.radio-switch__input[type="radio"]:nth-of-type(1):checked ~ .slide-item {
  left: 4px;
}
.radio-switch__input[type="radio"]:nth-of-type(2):checked ~ .slide-item {
  left: calc(14.28% + 4px);
}
.radio-switch__input[type="radio"]:nth-of-type(3):checked ~ .slide-item {
  left: calc(28.56% + 4px);
}
.radio-switch__input[type="radio"]:nth-of-type(4):checked ~ .slide-item {
  left: calc(42.84% + 4px);
}
.radio-switch__input[type="radio"]:nth-of-type(5):checked ~ .slide-item {
  left: calc(57.12% + 4px);
}
.radio-switch__input[type="radio"]:nth-of-type(6):checked ~ .slide-item {
  left: calc(71.4% + 4px);
}
.radio-switch__input[type="radio"]:nth-of-type(7):checked ~ .slide-item {
  left: calc(85.68% + 4px);
}
@media (max-width: 768px) {
  .radio-toggles {
    flex-direction: column;
    border-radius: 4px;
  }
  .slide-item {
    width: 103px;
    height: calc(10% + 4px);
    transition: top 0.4s;
    left: 50% !important;
    transform: translateX(-50%);
  }
  .radio-switch__label {
    width: auto;
    margin: 3px 0;
  }
  .radio-switch__input[type="radio"]:nth-of-type(1):checked ~ .slide-item {
    top: 6px;
  }
  .radio-switch__input[type="radio"]:nth-of-type(2):checked ~ .slide-item {
    top: calc(13.68% + 6px);
  }
  .radio-switch__input[type="radio"]:nth-of-type(3):checked ~ .slide-item {
    top: calc(28.56% + 4px);
  }
  .radio-switch__input[type="radio"]:nth-of-type(4):checked ~ .slide-item {
    top: calc(41.84% + 4px);
  }
  .radio-switch__input[type="radio"]:nth-of-type(5):checked ~ .slide-item {
    top: calc(56.12% + 4px);
  }
  .radio-switch__input[type="radio"]:nth-of-type(6):checked ~ .slide-item {
    top: calc(70.12% + 4px);
    width: 120px;
  }
  .dateStyle {
    padding: 50px 0 7px;
    transition: top 0.4s;
  }
  .dateStyle #custom-date-wrapper {
    position: absolute !important;
    margin-top: 4px;
  }
  .dateStyle label:not(:nth-of-type(7)) {
    display: none;
  }
  .dateStyle label:nth-of-type(7) {
    margin-top: 10px;
  }
  .dateStyle
    .radio-switch__input[type="radio"]:nth-of-type(7):checked
    ~ .slide-item {
    top: calc(56.1% + 4px);
    height: 30px;
  }
  .custom-date {
    top: 4px !important;
    left: 4px !important;
    width: calc(100% - 8px) !important;
    position: absolute !important;
  }
}
</style>
